import React from 'react';

const DashboardPage = React.lazy(() => import('../pages/dashboard'));

// INFO: Partenaires (liste et fiche)
const PartnersList = React.lazy(() => import('../pages/partners/list'));
const PartnersEdit = React.lazy(() => import('../pages/partners/edit'));
const OffersEdit = React.lazy(() => import('../pages/offers/edit'));
const OffersGroupsEdit = React.lazy(() => import('../pages/offersGroups/edit'));
const CodesList = React.lazy(() => import('../pages/codes/index'));

// INFO: Catégories (liste et fiche)
const CategoriesList = React.lazy(() => import('../pages/categories/list'));
const CategoriesEdit = React.lazy(() => import('../pages/categories/edit'));

// INFO: Publicité sur univers (liste et fiche)
const PartnersOnTopList = React.lazy(() => import('../pages/partnersOnTop/list'));
const PartnersOnTopEdit = React.lazy(() => import('../pages/partnersOnTop/edit'));

// INFO: Sliders (liste et fiche)
const SlidersList = React.lazy(() => import('../pages/sliders/list'));
const SlidersEdit = React.lazy(() => import('../pages/sliders/edit'));

// INFO: Seo (liste et fiche)
const SeosList = React.lazy(() => import('../pages/seo/list'));
const SeosEdit = React.lazy(() => import('../pages/seo/edit'));

// INFO: Bandeaux promos (liste et fiche)
const BannersList = React.lazy(() => import('../pages/banners/list'));
const BannersEdit = React.lazy(() => import('../pages/banners/edit'));

// INFO: Tranches de prix (liste et fiche)
const PricesRangeList = React.lazy(() => import('../pages/pricesrange/list'));
const PricesRangeEdit = React.lazy(() => import('../pages/pricesrange/edit'));

// INFO: Opérations commerciales (liste et fiche)
const OperationsList = React.lazy(() => import('../pages/operations/list'));
const OperationsEdit = React.lazy(() => import('../pages/operations/edit'));

// INFO: Opérations abondements (liste et fiche)
const ContributionsList = React.lazy(() => import('../pages/contributions/list'));
const ContributionsEdit = React.lazy(() => import('../pages/contributions/edit'));

// INFO: Sociétés (liste et fiche)
const SocietiesList = React.lazy(() => import('../pages/societies/list'));
const SocietiesItem = React.lazy(() => import('../pages/societies/edit'));
const SocietiesPartnersList = React.lazy(() => import('../pages/societies/partners/list'));

// INFO: Dashboard partenaires (liste et fiche)
const SocietyPartnersList = React.lazy(() => import('../pages/societyPartners/list'));
const SocietyPartnersItem = React.lazy(() => import('../pages/societyPartners/edit'));

// INFO: Code promo (liste et fiche)
const DiscountsList = React.lazy(() => import('../pages/discounts/list'));
const DiscountsEdit = React.lazy(() => import('../pages/discounts/edit'));

// INFO: Code promo parrainage (liste et fiche)
const DiscountsGodFatherList = React.lazy(() =>
  import('../pages/discountsGodFather/list'),
);

// INFO: Gamme de produit (liste et fiche)
const ProductsList = React.lazy(() => import('../pages/products/list'));
const ProductEdit = React.lazy(() => import('../pages/products/edit'));

// INFO: Carte mères (liste et fiche)
const CardsTemplateList = React.lazy(() =>
  import('../pages/cardstemplates/list'),
);
const CardsTemplateItem = React.lazy(() =>
  import('../pages/cardstemplates/edit'),
);

// INFO: Mots clés de recherche (liste et fiche)
const SearchesList = React.lazy(() => import('../pages/searches/list'));
const SearchesEdit = React.lazy(() => import('../pages/searches/edit'));

// INFO: Administrateurs (liste et fiche)
const AdministrtorsList = React.lazy(() =>
  import('../pages/administrators/list'),
);
const AdministrtorsEdit = React.lazy(() =>
  import('../pages/administrators/edit'),
);

// INFO: Utilisateurs (liste et fiche)
const UsersList = React.lazy(() => import('../pages/users/list'));
const UsersEdit = React.lazy(() => import('../pages/users/edit'));

// INFO: Opérations sur les sites (modales à la fin d'un achat etc…)
const OperationOnWebsiteList = React.lazy(() =>
  import('../pages/operationsOnWebsite/list'),
);
const OperationOnWebsiteEdit = React.lazy(() =>
  import('../pages/operationsOnWebsite/edit'),
);

// INFO: Route simples (juste des listes)

// INFO: Newsletter (liste)
const NewslettersList = React.lazy(() => import('../pages/newsletters'));

// INFO: Cartes (liste)
const CardsList = React.lazy(() => import('../pages/cards'));

// INFO: Offres achetées (liste)
const PurchasedOffersList = React.lazy(() =>
  import('../pages/purchasedOffers'),
);

// INFO: Mails (liste)
const MailsList = React.lazy(() => import('../pages/mails'));

// INFO: Journaux d'erreurs (liste)
const ErrorsLogsList = React.lazy(() => import('../pages/errors'));

// INFO: Liste de tous les utilisateurs sans limite de société
const WatchUsersList = React.lazy(() => import('../pages/watch-users'));

// INFO: Liste des tous les achats sans limite de société
// const WatchPurchasesList = React.lazy(() => import('../pages/watch-offers'));

// INFO: Offres packagées
const PacksList = React.lazy(() => import('../pages/packs/list'));
const PackEdit = React.lazy(() => import('../pages/packs/edit'));

// INFO: Langues
const LangsList = React.lazy(() => import('../pages/langs/list'));
const LangsEdit = React.lazy(() => import('../pages/langs/edit'));

// INFO: Liste des traductions du site
const Translations = React.lazy(() => import('../pages/translations'));

export default {
  dashboard: {
    name: 'Tableau de bord',
    list: '/tableau-de-bord',
    module: 'dashboard',
    bySociety: false,
    menu: true,
    Components: {
      list: DashboardPage,
    },
  },
  partners: {
    name: 'Partenaires',
    list: '/revendeurs/:resellerId/partenaires',
    edit: '/revendeurs/:resellerId/partenaires/:itemId',
    module: 'partners',
    bySociety: false,
    menu: true,
    Components: {
      list: PartnersList,
      edit: PartnersEdit,
    },
  },
  offers: {
    name: 'Offres',
    list: '/revendeurs/:resellerId/partenaires/:itemId#offres',
    edit: '/revendeurs/:resellerId/partenaires/:itemId/offres/:subItemId',
    module: 'offers',
    bySociety: false,
    Components: {
      // edit: DashboardPage,
      edit: OffersEdit,
    },
  },
  offersgroups: {
    name: 'Groupe d\'offres',
    list: '/revendeurs/:resellerId/partenaires/:itemId#groupes-d-offres',
    edit: '/revendeurs/:resellerId/partenaires/:itemId/groupes-d-offres/:subItemId',
    module: 'offers',
    bySociety: false,
    Components: {
      edit: OffersGroupsEdit,
    },
  },
  codes: {
    name: 'Codes',
    list: '/revendeurs/:resellerId/partenaires/:itemId/offres/:subItemId/codes/liste',
    edit: '/revendeurs/:resellerId/partenaires/:itemId/offres/:subItemId/codes/liste',
    module: 'codes',
    bySociety: false,
    Components: {
      // list: DashboardPage,
      list: CodesList,
    },
  },
  categories: {
    name: 'Catégories',
    list: '/categories/liste',
    edit: '/categories/:itemId',
    module: 'categories',
    menu: true,
    bySociety: false,
    Components: {
      list: CategoriesList,
      edit: CategoriesEdit,
    },
  },
  partnersOnTop: {
    name: 'Partenaires à la une',
    list: '/revendeurs/:resellerId/societes/:societyId/partenaires-selectionnes/liste',
    edit: '/revendeurs/:resellerId/societes/:societyId/partenaires-selectionnes/:itemId',
    module: 'categories',
    menu: true,
    bySociety: true,
    Components: {
      edit: PartnersOnTopEdit,
      list: PartnersOnTopList,
    },
    parent: 'website',
  },
  users: {
    name: 'Utilisateurs',
    list: '/revendeurs/:resellerId/societes/:societyId/utilisateurs',
    edit: '/revendeurs/:resellerId/societes/:societyId/utilisateurs/:itemId',
    module: 'users',
    menu: true,
    bySociety: true,
    Components: {
      list: UsersList,
      edit: UsersEdit,
    },
  },
  discounts: {
    name: 'Codes promo',
    list: '/revendeurs/:resellerId/societes/:societyId/codes-promo',
    edit: '/revendeurs/:resellerId/societes/:societyId/codes-promo/:itemId',
    module: 'discounts',
    menu: true,
    bySociety: true,
    Components: {
      list: DiscountsList,
      edit: DiscountsEdit,
    },
  },
  discountsGodFather: {
    name: 'Codes parrainage',
    list: '/revendeurs/:resellerId/societes/:societyId/codes-parrainage',
    edit: '/revendeurs/:resellerId/societes/:societyId/codes-parrainage/:itemId',
    module: 'discounts',
    menu: true,
    bySociety: true,
    Components: {
      list: DiscountsGodFatherList,
    },
  },
  cards: {
    name: 'Cartes',
    list: '/revendeurs/:resellerId/societes/:societyId/cartes',
    module: 'cards',
    menu: true,
    bySociety: true,
    Components: {
      list: CardsList,
    },
  },
  cardstemplate: {
    name: 'Cartes mères',
    list: '/revendeurs/:resellerId/societes/:societyId/cartes-meres',
    edit: '/revendeurs/:resellerId/societes/:societyId/cartes-meres/:itemId',
    module: 'cardstemplate',
    menu: true,
    bySociety: true,
    Components: {
      list: CardsTemplateList,
      edit: CardsTemplateItem,
    },
    parent: 'website',
  },
  pricesrange: {
    name: 'Tranches de prix',
    list: '/revendeurs/:resellerId/societes/:societyId/tranches-de-prix',
    edit: '/revendeurs/:resellerId/societes/:societyId/tranches-de-prix/:itemId',
    module: 'pricesrange',
    menu: true,
    bySociety: true,
    Components: {
      list: PricesRangeList,
      edit: PricesRangeEdit,
    },
    parent: 'website',
  },
  products: {
    name: 'Gamme de produits',
    list: '/revendeurs/:resellerId/societes/:societyId/gamme-de-produits',
    edit: '/revendeurs/:resellerId/societes/:societyId/gamme-de-produits/:itemId',
    module: 'products',
    menu: true,
    bySociety: true,
    Components: {
      list: ProductsList,
      edit: ProductEdit,
    },
  },
  packs: {
    name: 'Offres packagées',
    list: '/revendeurs/:resellerId/societes/:societyId/offres-packagees',
    edit: '/revendeurs/:resellerId/societes/:societyId/offres-packagees/:itemId',
    module: 'packs',
    menu: true,
    bySociety: true,
    Components: {
      list: PacksList,
      edit: PackEdit,
    },
  },
  searches: {
    name: 'Recherches',
    list: '/revendeurs/:resellerId/societes/:societyId/recherches',
    edit: '/revendeurs/:resellerId/societes/:societyId/recherches/:itemId',
    module: 'searches',
    menu: true,
    bySociety: true,
    Components: {
      list: SearchesList,
      edit: SearchesEdit,
    },
    parent: 'website',
  },
  purchases: {
    name: 'Offres achetées',
    list: '/revendeurs/:resellerId/societes/:societyId/offres-achetees',
    module: 'purchases',
    menu: true,
    bySociety: true,
    Components: {
      list: PurchasedOffersList,
    },
  },
  societies: {
    name: 'Sociétés',
    list: '/revendeurs/:resellerId/societes',
    edit: '/revendeurs/:resellerId/societes/:itemId',
    module: 'societies',
    menu: true,
    Components: {
      list: SocietiesList,
      edit: SocietiesItem,
    },
  },
  societiesPartners: {
    name: 'Partenaires autorisés',
    list: '/revendeurs/:resellerId/societes/:societyId/partenaires',
    module: 'societies',
    menu: false,
    Components: {
      list: SocietiesPartnersList,
    },
  },
  societypartners: {
    name: 'Dashboard partenaires',
    list: '/dashboard-partenaires',
    edit: '/dashboard-partenaires/:itemId',
    module: 'societypartners',
    menu: true,
    Components: {
      list: SocietyPartnersList,
      edit: SocietyPartnersItem,
    },
  },
  seos: {
    name: 'Seo',
    list: '/revendeurs/:resellerId/societes/:societyId/seo',
    edit: '/revendeurs/:resellerId/societes/:societyId/seo/:itemId',
    module: 'seos',
    menu: true,
    bySociety: true,
    Components: {
      list: SeosList,
      edit: SeosEdit,
    },
    parent: 'website',
  },
  banners: {
    name: 'Bandeaux promos',
    list: '/revendeurs/:resellerId/societes/:societyId/bannieres',
    edit: '/revendeurs/:resellerId/societes/:societyId/bannieres/:itemId',
    module: 'banners',
    menu: true,
    bySociety: true,
    Components: {
      list: BannersList,
      edit: BannersEdit,
    },
    parent: 'website',
  },
  operations: {
    name: 'Opérations commerciales',
    list: '/revendeurs/:resellerId/operations-commerciales',
    edit: '/revendeurs/:resellerId/operations-commerciales/:itemId',
    module: 'operations',
    menu: true,
    bySociety: false,
    Components: {
      list: OperationsList,
      edit: OperationsEdit,
    },
    parent: 'website',
  },
  contributions: {
    name: 'Abondements',
    list: '/revendeurs/:resellerId/societes/:societyId/abondements',
    edit: '/revendeurs/:resellerId/societes/:societyId/abondements/:itemId',
    module: 'operations',
    menu: true,
    bySociety: true,
    Components: {
      list: ContributionsList,
      edit: ContributionsEdit,
    },
    parent: 'website',
  },
  newsletters: {
    name: 'Newsletter',
    list: '/revendeurs/:resellerId/societes/:societyId/newsletter',
    module: 'newsletters',
    menu: true,
    bySociety: true,
    Components: {
      list: NewslettersList,
    },
    parent: 'other',
  },
  newslettersPartners: {
    name: 'Newsletter partenaires',
    list: '/revendeurs/:resellerId/societes/:societyId/newsletter-partners',
    module: 'newsletters',
    menu: true,
    bySociety: true,
    Components: {
      list: NewslettersList,
    },
    parent: 'other',
  },
  sliders: {
    name: 'Sliders',
    list: '/revendeurs/:resellerId/societes/:societyId/sliders',
    edit: '/revendeurs/:resellerId/societes/:societyId/sliders/:itemId',
    module: 'sliders',
    menu: true,
    bySociety: true,
    Components: {
      list: SlidersList,
      edit: SlidersEdit,
    },
    parent: 'website',
  },
  operationsOnWebsite: {
    name: 'Modales',
    list: '/revendeurs/:resellerId/societes/:societyId/modales',
    edit: '/revendeurs/:resellerId/societes/:societyId/modales/:itemId',
    module: 'operationsOnWebsite',
    menu: true,
    bySociety: true,
    Components: {
      list: OperationOnWebsiteList,
      edit: OperationOnWebsiteEdit,
    },
    parent: 'website',
  },
  admins: {
    name: 'Admin/Commerciaux',
    list: '/administrateurs',
    edit: '/administrateurs/:itemId',
    module: 'admins',
    menu: true,
    bySociety: false,
    Components: {
      list: AdministrtorsList,
      edit: AdministrtorsEdit,
    },
    parent: 'administration',
  },
  langs: {
    name: 'Langues',
    list: '/langues',
    edit: '/langues/:itemId',
    module: 'langs',
    menu: true,
    bySociety: false,
    Components: {
      list: LangsList,
      edit: LangsEdit,
    },
    parent: 'administration',
  },
  mails: {
    name: 'Mails',
    list: '/mails',
    module: 'mails',
    menu: true,
    bySociety: false,
    Components: {
      list: MailsList,
    },
    parent: 'administration',
  },
  errrorLogs: {
    name: "Journaux d'erreurs",
    list: '/journaux-d-erreurs',
    module: 'logs',
    menu: true,
    bySociety: false,
    Components: {
      list: ErrorsLogsList,
    },
    parent: 'administration',
  },
  banMachine: {
    name: 'Tous les utilisateurs',
    list: '/tous-les-utilisateurs',
    module: 'users',
    menu: true,
    bySociety: false,
    Components: {
      list: WatchUsersList,
    },
    parent: 'administration',
  },
  allPurchases: {
    name: 'Tous les offres achetées',
    list: '/tous-les-offres-achetees',
    module: 'purchases',
    menu: true,
    bySociety: false,
    Components: {
      list: PurchasedOffersList,
    },
    parent: 'administration',
  },
  translations: {
    name: 'Traductions',
    list: '/traductions',
    module: 'translations',
    menu: true,
    bySociety: false,
    Components: {
      list: Translations,
    },
    parent: 'website',
  },
  website: {
    name: 'Configuration du site',
    list: '/site',
    module: 'dashboard',
    menu: true,
    bySociety: true,
    type: 'expander',
  },
  administration: {
    name: 'Administration',
    list: '/administration',
    module: 'dashboard',
    menu: true,
    bySociety: true,
    type: 'expander',
  },
  other: {
    name: 'Autre',
    list: '/autre',
    module: 'dashboard',
    menu: true,
    bySociety: true,
    type: 'expander',
  },
};
